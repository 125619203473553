import request from '../utils/request';
import qs from "qs";
// eslint-disable-next-line no-unused-vars
export const fetchCompany = currentUserId => {
	return request.get('/api/company/all?currentUserId=' + currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDepartment = data => {
	return request.get('/api/department/all?companyId=' + data.companyId + '&&currentUserId=' + data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchUsers = userId => {
	return request.get('/api/user/users')
};

export const fetchUsersByCompanyId = companyId => {
	return request.get('/api/user/fetchUsersByCompanyId?companyId=' + companyId)
};

export const fetchUsersByDepartmentId = data => {
	return request.get('/api/user/fetchUsersByDepartmentId?departmentId=' + data.departmentId + '&&currentUserId=' +
		data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDefaultUsers = currentUserId => {
	return request.get('/api/user/fetchDefaultUsers?currentUserId=' + currentUserId)
};


export const getOperationRecord = param => {
	return request.post('/api/user/getOperationRecord', qs.stringify(param))
};
export const refreshToken = param => {
	return request.post('/api/login/refreshToken', qs.stringify(param))
};



export const maximumRecordList = param => {
	return request.post('/api/record/maximumRecord', qs.stringify(param))
};
export const completeToday = param => {
	return request.post('/api/record/completeToday', qs.stringify(param))
};

export const riskList = param => {
	return request.post('/api/record/riskList', qs.stringify(param))
};
export const riskRecordUpdate = param => {
	return request.post('/api/record/riskRecordUpdate', qs.stringify(param))
};
export const qualityRecord = param => {
	return request.post('/api/record/qualityRecord', qs.stringify(param))
};
export const AIInspectionList = param => {
	return request.post('/api/record/AIInspectionList', qs.stringify(param))
};
export const QualityCompany = userId => {
	return request.get('/api/record/QualityCompany?userId=' + userId)
};

export const AiRiskRecord = param => {
	return request.post('/api/record/AiRiskRecord', qs.stringify(param))
};

export const getriskTel = param => {
	return request.post('/api/blackList/riskTel', qs.stringify(param))
};
export const riskRecord = param => {
	return request.post('/api/record/riskRecord', qs.stringify(param))
};
