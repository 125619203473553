import request from '../utils/request';
import qs from "qs";

export const list = param => {
    return request.post('/api/serial/list', qs.stringify(param))
};

export const userSerial = param => {
    return request.post('/api/serial/userSerial', qs.stringify(param))
};

export const cancel = id => {
    return request.get('/api/serial/cancel?id='+id)
};
